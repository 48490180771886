import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PwaService } from 'src/app/services/pwa.service';
import {
  AlertController,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  Platform,
} from '@ionic/angular/standalone';
import { PushService } from 'src/app/services/push.service';
import { Subscription } from 'rxjs';
import { PushNotifications } from '@capacitor/push-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import {
  IonContent,
  IonBackButton,
  IonToggle,
} from '@ionic/angular/standalone';

type PermissionType = 'notifications' | 'location';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.page.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InlineSVGModule,
    TranslateModule,
    IonContent,
    IonBackButton,
    IonToggle,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
  ],
})
export class PermissionsPage implements OnInit, OnDestroy {
  permissionsForm: FormGroup;
  private resumeSubscription: Subscription;
  isBackground: boolean = false;

  constructor(
    private pushService: PushService,
    private fb: FormBuilder,
    private platform: Platform,
    private ngZone: NgZone,
    private pwaService: PwaService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.createPermissionsForm();
  }

  ngOnInit() {
    if (this.pwaService.isPwa()) {
      this.router.navigate(['/settings']);
      return;
    }

    this.checkStatuses(false);
    this.resumeSubscription = this.platform.resume.subscribe(() => {
      if (this.isBackground) {
        this.checkStatuses();
        this.isBackground = false;
      }
    });
  }

  ngOnDestroy() {
    this.resumeSubscription.unsubscribe();
  }

  createPermissionsForm() {
    this.permissionsForm = this.fb.group({
      notificationsEnabled: [false],
      locationEnabled: [false],
    });

    this.permissionsForm
      .get('notificationsEnabled')
      .valueChanges.subscribe(async (value) => {
        if (!value) {
          this.showAlert('notifications');
        } else {
          const permission = await PushNotifications.requestPermissions();
          if (permission.receive === 'granted') {
            await this.pushService.enablePush();
          } else {
            this.showAlert('notifications');
          }
        }
      });

    this.permissionsForm
      .get('locationEnabled')
      .valueChanges.subscribe(async (value) => {
        if (!value) {
          this.showAlert('location');
        } else {
          const permission = await Geolocation.requestPermissions();
          if (
            permission.location !== 'granted' &&
            permission.coarseLocation !== 'granted'
          ) {
            this.showAlert('location');
          }
        }
      });
  }

  openNativeSettings(type: PermissionType) {
    NativeSettings.open({
      optionAndroid:
        type == 'notifications'
          ? AndroidSettings.AppNotification
          : AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    }).then(() => {
      this.isBackground = true;
    });
  }

  async showAlert(type: PermissionType) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant(
        'settings.permissions.alert.header.' + type
      ),
      message: this.translateService.instant(
        'settings.permissions.alert.message.' + type
      ),
      buttons: [
        {
          text: this.translateService.instant('settings.cancel'),
          role: 'cancel',
          handler: () => {
            if (type == 'notifications')
              this.permissionsForm
                .get('notificationsEnabled')
                .setValue(
                  !this.permissionsForm.get('notificationsEnabled').value,
                  { emitEvent: false }
                );
            else
              this.permissionsForm
                .get('locationEnabled')
                .setValue(!this.permissionsForm.get('locationEnabled').value, {
                  emitEvent: false,
                });
          },
        },
        {
          text: this.translateService.instant('settings.go-to-system'),
          handler: () => {
            this.openNativeSettings(type);
          },
        },
      ],
    });

    await alert.present();
  }

  async checkStatuses(updatePush: boolean = true) {
    let notificationsEnabled = false;
    let locationEnabled = false;

    notificationsEnabled =
      (await LocalNotifications.checkPermissions()).display === 'granted'
        ? true
        : false;
    try {
      let locationPermissions = await Geolocation.checkPermissions();
      locationEnabled =
        locationPermissions.location === 'granted' ||
        locationPermissions.coarseLocation === 'granted'
          ? true
          : false;
    } catch (error) {
      locationEnabled = false;
    }

    this.ngZone.run(() => {
      this.permissionsForm
        .get('notificationsEnabled')
        .setValue(notificationsEnabled, { emitEvent: false });
      this.permissionsForm
        .get('locationEnabled')
        .setValue(locationEnabled, { emitEvent: false });
    });

    if (!updatePush) {
      return;
    }

    if (notificationsEnabled) {
      await this.pushService.enablePush();
    } else {
      await this.pushService.disablePush();
    }
  }
}
