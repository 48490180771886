<div [class.card--summary__inner]="withPadding">
  <div class="card--summary__head">
    <div>
      <h2>{{ 'feed.survey.title' | translate }}</h2>
    </div>
  </div>
  <ngx-skeleton-loader *ngIf="loading" [theme]="{ height: '100px' }">
  </ngx-skeleton-loader>
  <ng-container *ngIf="canShowSurvey && !loading">
    <div class="card--summary__title">
      <h3>{{ survey.title }}</h3>
    </div>
    <div class="card__content">
      <div
        class="card--summary__description"
        [appTranslateText]="survey.description"
        [language]="language"
        [html]="survey.description"
      ></div>
    </div>
    <a tabindex="0" class="card--summary__button" (click)="open()">
      <span>{{ 'feed.survey.action' | translate }}</span>
      <span
        class="icon"
        [inlineSVG]="'/assets/icons/card/arrow-right.svg'"
      ></span>
    </a>
  </ng-container>
  <ng-container *ngIf="!canShowSurvey && !loading">
    <div class="card--summary__title">
      <h3>{{ 'feed.survey.sent.title' | translate }}</h3>
    </div>
    <div class="card--summary__description">
      <span>{{ 'feed.survey.sent.description' | translate }}</span>
    </div>
  </ng-container>
</div>
