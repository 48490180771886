import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  Renderer2,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { CurrentWhiteLabelApplication } from './utils/current-white-label-application';
import { NetworkService } from './services/network.service';
import { FileService } from './services/file.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StatusBarService } from './services/status-bar.service';
import * as moment from 'moment';
import { BackButtonService } from './services/back-button.service';
import { PwaService } from './services/pwa.service';
import { KeyboardService } from './services/keyboard.service';
import { WhiteLabelApplicationService } from './services/white-label-application.service';
import { TranslationService } from './services/translation.service';
import { SettingsService } from './services/settings.service';
import { DOCUMENT, NgIf } from '@angular/common';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeepLinkService } from './services/deep-link.service';
import { PushService } from './services/push.service';
import { AnalyticsService } from './services/analytics.service';
import { Device } from '@capacitor/device';
import { AnalyticsType } from './enums/analytics-type';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { StorageService } from './services/storage.service';
import { GlobalErrorHandler } from './utils/gloabl-error-handler';
import { DeviceService } from './services/device.service';
import { DeviceRedirectService } from './services/device-redirect.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [NgIf, LoaderComponent, IonApp, IonRouterOutlet],
})
export class AppComponent implements OnDestroy {
  ready = false;
  checked = false;
  readonly HAS_OPENED_KEY = 'hasOpenedApp';

  constructor(
    private title: Title,
    private translate: TranslateService,
    private apiService: ApiService,
    private currentApp: CurrentWhiteLabelApplication,
    private networkService: NetworkService,
    private fileService: FileService,
    private router: Router,
    private statusBarService: StatusBarService,
    private backButtonService: BackButtonService,
    private pwaService: PwaService,
    private whiteLabelApplicationService: WhiteLabelApplicationService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private keyboardService: KeyboardService,
    private translationService: TranslationService,
    private settingsService: SettingsService,
    private pushService: PushService,
    private deeplinkService: DeepLinkService,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
    private deviceService: DeviceService,
    private deviceRedirectService: DeviceRedirectService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initializeApp();
    this.apiService.userLoggedOut.subscribe(() => {
      SplashScreen.show();
      this.ready = false;
      this.initializeApp();
    });
  }

  async initializeApp() {
    await this.whiteLabelApplicationService.check();
    this.setupLocalization();
    await this.apiService.init();

    this.deeplinkService.checkDeepLinks();
    this.addLinkListener();

    await this.deviceRedirectService.checkAppStoreRedirect();
    if (!(await this.storageService.get(this.HAS_OPENED_KEY))) {
      await this.deviceRedirectService.checkRedirect();
    }

    if (this.pwaService.isPwa()) {
      this.allowZoom();
      this.analyticsService.removeUtmSourceFromUrl(
        new URL(window.location.href)
      );
    }

    this.whiteLabelApplicationService.initialize();
    this.ready = true;
    this.cd.detectChanges();
    SplashScreen.hide();

    this.title.setTitle(this.currentApp.getName());

    this.settingsService.checkContrast();
    if (this.pwaService.isPwa()) {
      this.allowZoom();
    }

    // Temporary to fix devices for non bouwapp PWAs that are linked to wrong PWA. BA-1774
    if (
      this.pwaService.isPwa() &&
      window.location.hostname.split(':')[0] !== 'debouw.app'
    ) {
      this.deviceService.syncWhiteLabelApplication();
    }

    if (!this.pwaService.isPwa()) {
      this.analyticsService.logEvent(AnalyticsType.APP_STARTUP, {});
    }
    const language = await Device.getLanguageCode();
    this.analyticsService.logEvent(AnalyticsType.DEVICE_LANGUAGE, {
      language: language.value,
    });

    this.pushService.setupFirebaseListeners();

    this.fileService.removeScheduledFiles();

    this.networkService.initialize();

    this.translationService.setTranslationTitles();
    this.setupDevice();

    try {
      await this.deviceService.getData();
    } catch (error) {
      // ignore
    }

    this.backButtonService.attach();

    (await this.settingsService.osPushEnabled())
      ? this.pushService.enablePush()
      : this.pushService.disablePush();
    this.analyticsService.initAnalytics();

    this.storageService.remove(GlobalErrorHandler.RELOAD_COUNT_KEY);
    this.storageService.remove(GlobalErrorHandler.LAST_RELOAD_TIME_KEY);

    if (!(await this.storageService.get(this.HAS_OPENED_KEY))) {
      await this.storageService.set(this.HAS_OPENED_KEY, true);
    }
  }

  allowZoom() {
    const metaTag = this.document.querySelector('meta[name="viewport"]');
    const content =
      'minimum-scale=1.0, maximum-scale=5.0, initial-scale=1.0, user-scalable=yes, viewport-fit=cover, width=device-width';
    this.renderer.setAttribute(metaTag, 'content', content);
  }

  setupDevice() {
    this.statusBarService.whiteTop();

    this.keyboardService.hideAccessoryBar();
  }

  setupLocalization() {
    this.translate.setDefaultLang(TranslationService.DEFAULT_LANG);
    let language = this.translationService.getAppLanguage();
    moment.locale(language);
    this.translate.use(language);
    this.document.documentElement.lang = language;
  }

  ngOnDestroy() {
    this.backButtonService.detach();
  }

  private addLinkListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.analyticsService.removeUtmSourceFromUrl(new URL(event.url));
        if (
          event.url.startsWith(environment.linkPrefix + '://') ||
          event.url.startsWith(DeepLinkService.PUSH_LINK_PREFIX + '://')
        ) {
          this.router.navigate(['deeplink', 'wrapper'], {
            queryParams: { link: event.url },
          });
        } else if (event.url.startsWith('https://')) {
          this.router.navigate(new URL(event.url).pathname.split('/'));
        }
      });
    });
  }
}
