<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/" mode="md"></ion-back-button>
    </ion-buttons>
    <ion-title role="heading" aria-level="1" mode="md"
      >{{ 'page.settings.about-app' | translate }}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="app__content about-app">
    <p
      [language]="language"
      [appTranslateText]="aboutText | contentText"
      [html]="aboutText | contentText"
    ></p>
  </div>
</ion-content>
