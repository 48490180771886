export enum CardType {
  UPDATE = 'update',
  RATING = 'rating',
  SURVEY = 'survey',
  WELCOME = 'welcome',
  CONTACT = 'contact',
  CHILDPROJECT = 'child-project',
  REPORT = 'report',
  GENERAL = 'message',
  PROJECT_SUGGESTION = 'project-suggestion',
  EVENTS = 'events',
  ORGANISATION = 'organisation',
}
