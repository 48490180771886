import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import {
  IonContent,
  IonBackButton,
  IonHeader,
  IonToolbar,
  IonButtons,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  standalone: true,
  imports: [
    NgIf,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    TranslateModule,
    IonContent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
  ],
})
export class LoginPage implements OnInit {
  appIcon: string;
  appName: string;
  sent: boolean = false;
  failed: boolean = false;
  loginForm: FormGroup;
  loading: boolean = false;

  constructor(
    private app: CurrentWhiteLabelApplication,
    private fb: FormBuilder,
    private loginService: LoginService
  ) {
    this.appIcon = this.app.getIconThumbnails()?.small;
    this.appName = this.app.getName();
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      this.loginService
        .sendMagicLink(this.loginForm.value)
        .then(() => {
          this.sent = true;
        })
        .catch(() => {
          this.failed = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
