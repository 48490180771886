import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { Platform } from '@ionic/angular/common';
import { RedirectDeviceData } from '../interfaces/redirect-device-data';
import { firstValueFrom } from 'rxjs';
import { Device } from '@capacitor/device';
import { PwaService } from './pwa.service';
import { AnalyticsService } from './analytics.service';
import { Router } from '@angular/router';
import { Referrer } from '../plugins/referrer';

@Injectable({ providedIn: 'root' })
export class DeviceRedirectService {
  constructor(
    private apiService: ApiService,
    private currentApp: CurrentWhiteLabelApplication,
    private pwaService: PwaService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private platform: Platform
  ) {}

  private async storeRedirectUrl(data: RedirectDeviceData): Promise<void> {
    return firstValueFrom(
      this.apiService.identifiedPost(
        '/api/v2/devices/redirect-data/store',
        data
      )
    );
  }

  private async retrieveRedirectUrl(data: RedirectDeviceData): Promise<string> {
    return firstValueFrom(
      this.apiService.identifiedPost('/api/v2/devices/redirect-data/get', data)
    );
  }

  private async getRedirectUrl(): Promise<URL> {
    if (this.platform.is('android')) {
      try {
        const details = await Referrer.getReferrerDetails();
        if (details && details.referrerUrl) {
          return new URL(details.referrerUrl);
        }
      } catch (e) {
        // No referrer or unsupported (Play Store not installed for example)
        return null;
      }
    } else if (this.platform.is('ios')) {
      try {
        const url = await this.retrieveRedirectUrl(
          await this.getRedirectDeviceData()
        );
        return new URL(url);
      } catch (e) {
        // Nothing found for this device
        return null;
      }
    }
  }

  public async checkAppStoreRedirect() {
    if (!this.pwaService.isPwa() || !this.platform.is('ios')) {
      return;
    }

    try {
      const queryParams = new URLSearchParams(
        new URL(window.location.href).search
      );

      if (!queryParams.has('utm_source')) {
        return;
      }

      const appStoreId = this.currentApp.getIOSAppStoreId();
      if (appStoreId) {
        await this.storeRedirectUrl(
          await this.getRedirectDeviceData(window.location.href)
        );
        window.location.href = 'https://apps.apple.com/app/' + appStoreId;
      }
    } catch (e) {
      // Ignore
    }
  }

  private async getRedirectDeviceData(
    url: string = null
  ): Promise<RedirectDeviceData> {
    const width = window.screen.width;
    const height = window.screen.height;
    return {
      screenWidth: width,
      screenHeight: height,
      whiteLabelApplicationId: this.currentApp.getId(),
      redirectUrl: url,
    };
  }

  public async checkRedirect() {
    if (this.pwaService.isPwa()) {
      return;
    }

    const url = await this.getRedirectUrl();
    if (url) {
      this.analyticsService.removeUtmSourceFromUrl(url);
      await this.router.navigate(url.pathname.split('/'));
    }
  }
}
