import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UpdateAttachment } from 'src/app/interfaces/update-attachment';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { Survey } from 'src/app/interfaces/survey';
import { Event } from 'src/app/interfaces/event';
import { Share } from '@capacitor/share';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { Router } from '@angular/router';
import { WidgetService } from 'src/app/services/widget.service';
import { UpdateLikeType } from 'src/app/enums/update-like-type';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { TranslateModule } from '@ngx-translate/core';
import { CardOrganisationComponent } from './card-organisation/card-organisation.component';
import { CardProjectSuggestionComponent } from './card-project-suggestion/card-project-suggestion.component';
import { CardChildProjectComponent } from './card-child-project/card-child-project.component';
import { CardContactComponent } from './card-contact/card-contact.component';
import { CardRatingComponent } from './card-rating/card-rating.component';
import { CardSurveyComponent } from './card-survey/card-survey.component';
import { CardUpdateComponent } from './card-update/card-update.component';
import { CardEventsComponent } from './card-events/card-events.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ImgFallBackDirective } from '../../../directives/img-fallback.directive';
import { NgClass, NgIf, DatePipe } from '@angular/common';
import { DivClickDirective } from 'src/app/directives/div-click.directive';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ImgFallBackDirective,
    InlineSVGModule,
    CardEventsComponent,
    CardUpdateComponent,
    CardSurveyComponent,
    CardRatingComponent,
    CardContactComponent,
    CardChildProjectComponent,
    CardProjectSuggestionComponent,
    CardOrganisationComponent,
    DatePipe,
    TranslateModule,
    DivClickDirective,
  ],
})
export class CardComponent implements OnInit {
  canAutoplay: boolean = false;
  canShare: boolean = false;
  CardType = CardType;
  images: UpdateAttachment[];

  @Input() withLink: boolean = false;
  @Input() className: string;
  @Input() withHeader: boolean = false;
  @Input() withCoverImage: boolean = false;
  @Input() withNavigation: boolean = true;
  @Input() withFollowers: boolean = true;
  @Input() project: Project;
  @Input() childProject: Project;
  @Input() update: Update;
  @Input() type: CardType;
  @Input() dateAdded: Date;
  @Input() survey: Survey;
  @Input() language: string;
  @Input() category: string;
  @Input() likedType: UpdateLikeType;
  @Input() events: Event[];
  @Output() switchTab: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemVisible = new EventEmitter<Update>();

  private observer: IntersectionObserver;
  private isVisibleTimer: any;

  constructor(
    private router: Router,
    private selfReference: ElementRef,
    private app: CurrentWhiteLabelApplication,
    private widgetService: WidgetService,
    private analyticsService: AnalyticsService
  ) {
    if (!this.widgetService.getIsEmbedded())
      Share.canShare().then(
        (result) => (this.canShare = result.value && !!app.getPwaDomain())
      );
  }

  ngOnInit() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        if (!this.update) {
          return;
        }
        const entry = entries[0];
        if (entry.isIntersecting) {
          clearTimeout(this.isVisibleTimer);

          this.isVisibleTimer = setTimeout(() => {
            this.itemVisible.emit(this.update);
          }, 2000);
        } else {
          clearTimeout(this.isVisibleTimer);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.6,
      }
    );
    this.observer.observe(this.selfReference.nativeElement);
  }

  openItem() {
    if (this.widgetService.getIsEmbedded()) {
      this.widgetService.openApp();
      return;
    }

    let url;
    let state;
    switch (this.type) {
      case CardType.UPDATE:
        url = ['update', this.update.id];
        if (this.router.url.includes('/projects/'))
          state = { fromProject: true, project: this.project };
        break;
      case CardType.RATING:
        url = ['feedback'];
        state = { project: this.project };
        break;
      case CardType.SURVEY:
        url = ['survey', this.survey.id];
        state = { project: this.project };
        break;
      case CardType.PROJECT_SUGGESTION:
        this.openSuggestedProject(this.project);
        return;
      case CardType.CHILDPROJECT:
        this.openChildProject(this.childProject);
        return;
      case CardType.REPORT:
        url = ['smart-reporting'];
        state = { project: this.project };
        break;
      case CardType.CONTACT:
        this.switchTab.next('contact');
        return;
      case CardType.ORGANISATION:
        this.router.navigate(['organisations', this.project.customer.slug], {
          state: { language: this.project.language },
        });
        return;
      default:
        break;
    }

    this.router.navigate(['projects', this.project.slug, ...url], {
      state: state,
    });
  }

  openItemOrigin() {
    if (this.project !== null) {
      this.openProject(this.project);
    } else {
      this.router.navigate(['organisations', this.update.customer.slug], {
        state: { language: null },
      });
    }
  }

  openChildProject(project: Project) {
    this.router.navigate(['projects', project.slug], {
      state: { previousProject: this.router.url },
    });
  }

  openProject(project: Project) {
    if (this.widgetService.getIsEmbedded()) return;
    if (this.app.isProjectApp()) {
      this.router.navigate(['project']);
    } else {
      if (this.type == CardType.PROJECT_SUGGESTION)
        this.openSuggestedProject(this.project);
      else this.router.navigate(['projects', project.slug]);
    }
  }

  openSuggestedProject(project: Project) {
    this.router.navigate(['projects', project.slug]);
  }

  async share(event) {
    event.stopPropagation();

    let urlBase = 'projects';
    let slug = this.project?.slug;

    if (this.update?.customer) {
      urlBase = 'organisations';
      slug = this.update.customer.slug;
    }

    await Share.share({
      url: `https://${this.app.getPwaDomain()}/${urlBase}/${slug}/update/${
        this.update.id
      }`,
    }).catch((e) => {});

    this.analyticsService.logEvent(
      AnalyticsType.UPDATE_SHARED,
      {
        update_id: this.update.id,
      },
      this.project
    );
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
