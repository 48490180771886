<ion-header [hidden]="fullScreen" [class.ion-no-border]="!isSettings">
  <ion-toolbar color="light">
    <ion-buttons *ngIf="isSettings" slot="start">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </ion-buttons>
    <ion-title [hidden]="!isSettings" role="heading" aria-level="1" mode="md">
      {{ 'address.list.locations.new' | translate }}
    </ion-title>
    <ion-buttons *ngIf="!isSettings" slot="end">
      <ion-button size="small" color="primary" role="link" (click)="skip()">
        {{ 'onboarding.step.skip' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="app__content address">
    <div class="address__map" [class.fullscreen]="fullScreen">
      <div
        role="button"
        tabindex="0"
        (keydown)="hideOverlay()"
        (touchstart)="hideOverlay()"
        (mousedown)="hideOverlay()"
        *ngIf="showZoomOverlay"
        class="address__map__overlay"
      >
        <img
          aria-hidden="true"
          [src]="isDesktop ? '/assets/icons/center.svg' : '/assets/icons/pinch.svg'"
        />
        <p>{{ 'address.create.desc' | translate }}</p>
      </div>
      <app-loader [full]="true" [loading]="loading"></app-loader>
      <app-map
        (mapLoaded)="mapLoaded($event)"
        [minZoom]="2"
        [padding]="{bottom: 10}"
      ></app-map>
      <button
        *ngIf="!loading"
        class="button button-fullscreen"
        (click)="toggleFullscreen()"
        [attr.aria-label]="'address.create.fullscreen' | translate"
      >
        <span [inlineSVG]="'assets/icons/fullscreen.svg'"></span>
      </button>
    </div>

    <div class="address__content map" [class.fullscreen]="fullScreen">
      <div class="address__content__top">
        <h2>{{ 'address.create.title' | translate }}</h2>
        <p
          [ngClass]="{ 'hidden': !zoomError && loadingHistoricalUpdates}"
          [innerHTML]="(zoomError ? 'address.create.zoom-error' : 'address.historical-updates.' + historicalUpdateRange) | translate"
        ></p>
        <button
          class="follow"
          [disabled]="zoomError || loadingButton"
          (click)="getAddress()"
        >
          {{ 'address.create.follow' | translate }}
        </button>
      </div>
    </div>
  </div>
</ion-content>
