<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button mode="md" defaultHref="settings"></ion-back-button>
    </ion-buttons>
    <ion-title role="heading" aria-level="1" mode="md">
      {{ 'page.settings.preferences' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="app__description">
    <p>{{ 'page.settings.preferences.desc' | translate }}</p>
  </div>
  <div class="app__content preferences" [formGroup]="preferencesForm">
    <div class="preferences__box">
      <div class="preferences__box__content">
        <h2>{{ 'settings.preferences.suggestions.title' | translate }}</h2>
        <p>{{ 'settings.preferences.suggestions.desc' | translate }}</p>
      </div>
      <div class="preferences__box__toggle">
        <ion-toggle
          [attr.aria-label]="'settings.preferences.suggestions.title' | translate"
          formControlName="suggestionsEnabled"
        ></ion-toggle>
      </div>
    </div>
    <div
      role="button"
      tabindex="0"
      class="preferences__box"
      (click)="showDistanceTypes()"
    >
      <div class="preferences__box__content">
        <h2>{{ 'settings.preferences.distance-unit.title' | translate }}</h2>
        <p>{{ 'settings.preferences.distance-unit.desc' | translate }}</p>
      </div>
      <div class="preferences__box__setting">
        <span
          *ngIf="preferencesForm.get('distanceType').value == DistanceType.KM"
        >
          {{ 'settings.preferences.distance-unit.km' | translate }}</span
        >
        <span
          *ngIf="preferencesForm.get('distanceType').value == DistanceType.MILES"
        >
          {{ 'settings.preferences.distance-unit.mi' | translate }}</span
        >
      </div>
      <div
        class="preferences__box__icon"
        [inlineSVG]="'/assets/icons/arrow-right.svg'"
      ></div>
    </div>
    <div
      role="button"
      tabindex="0"
      class="preferences__box"
      (click)="showContrastOptions()"
    >
      <div class="preferences__box__content">
        <h2>{{ 'settings.preferences.contrast.title' | translate }}</h2>
        <p>{{ 'settings.preferences.contrast.desc' | translate }}</p>
      </div>
      <div class="preferences__box__setting">
        <span>
          {{ 'settings.preferences.contrast.' +
          preferencesForm.get('contrast').value | translate }}
        </span>
      </div>
      <div
        class="preferences__box__icon"
        [inlineSVG]="'/assets/icons/arrow-right.svg'"
      ></div>
    </div>
  </div>
</ion-content>
