import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({ providedIn: 'root' })
export class LocationService {
  public static DEFAULT_TIMEOUT = 5000;
  public lastPosition: GeolocationPosition = null;

  constructor() {}

  /**
   * Get current location with a timeout, and if no cached position is available, force to refresh
   * @param timeout
   * @returns {Promise<GeolocationPosition>}
   */
  public getCurrentPosition(
    timeout = LocationService.DEFAULT_TIMEOUT
  ): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
      const permissionResult = await this.hasPermission();
      if (!permissionResult) {
        resolve(null);
        return;
      }

      Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: timeout,
      })
        .then((value) => {
          this.lastPosition = value as GeolocationPosition;
          resolve(this.lastPosition);
        })
        .catch((error) => reject(error));
    });
  }

  public async hasAllPermissions(): Promise<boolean> {
    try {
      const permission = await Geolocation.checkPermissions();
      return (
        permission.location === 'granted' ||
        permission.coarseLocation === 'granted'
      );
    } catch {
      return false;
    }
  }

  private async hasPermission() {
    try {
      const permission = await Geolocation.checkPermissions();
      return (
        permission.location !== 'denied' ||
        permission.coarseLocation !== 'denied'
      );
    } catch (error) {
      return false;
    }
  }
}
