<div class="card--summary__inner">
  <div class="card--summary__head">
    <div>
      <h2>
        {{
          'card.organisation.title'
            | translate : { organisation: customer().name }
        }}
      </h2>
    </div>
  </div>
  <div class="card--summary__organisation">
    <div class="card__image default">
      <img
        *ngIf="customer().logoThumbnails"
        [src]="customer().logoThumbnails?.small"
        width="40"
        height="40"
        alt="{{ customer().name }}"
      />
    </div>
    <div class="card__content" *ngIf="customer().description">
      <div
        class="card--summary__description"
        [appTranslateText]="customer().description | truncate : 300"
        [html]="customer().description | truncate : 300"
        [language]="language()"
      ></div>
    </div>
  </div>
  <a
    *ngIf="canOpen()"
    tabindex="0"
    class="card--summary__link"
    (click)="open()"
  >
    {{ 'card.organisation.action' | translate }}
  </a>
</div>
