import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { RatingResponse } from '../interfaces/rating-response';
import { Project } from '../interfaces/project';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RatingService {
  private static PROJECT_IRI_PREFIX = '/api/projects/';
  public saved: EventEmitter<number> = new EventEmitter<number>();
  public ratingSubmitted: EventEmitter<number> = new EventEmitter<number>();

  constructor(private apiService: ApiService) {}

  /**
   * @param ratingResponse
   * @returns {Promise<any>}
   */
  public rate(ratingResponse: RatingResponse) {
    this.ratingSubmitted.next(null);
    return lastValueFrom(
      this.apiService.identifiedPost('/api/v2/rating-responses', {
        items: ratingResponse.items,
        project: RatingService.PROJECT_IRI_PREFIX + ratingResponse.project.slug,
        locationLat: ratingResponse.locationLat,
        locationLong: ratingResponse.locationLong,
      })
    ).then((result) => {
      this.ratingSubmitted.next(null);
      return result;
    });
  }

  /**
   * @param ratingResponse
   * @returns {Promise<any>}
   */
  public putRate(ratingResponse: RatingResponse, ratingId: number) {
    return lastValueFrom(
      this.apiService.identifiedPut('/api/v2/rating-responses/' + ratingId, {
        items: ratingResponse.items,
        project: RatingService.PROJECT_IRI_PREFIX + ratingResponse.project.slug,
        locationLat: ratingResponse.locationLat,
        locationLong: ratingResponse.locationLong,
      })
    ).then((result) => {
      this.ratingSubmitted.next(ratingResponse.project.id);
      return result;
    });
  }

  /**
   * Fetches the user's filled rating responses
   *
   * @param project
   * @returns {Promise<RatingResponse[]>}
   */
  public listByProject(project: Project): Promise<RatingResponse[]> {
    return this.apiService
      .identifiedGet(
        '/api/v2/projects/' +
          project.slug +
          '/rating-responses?order[createdAt]=desc'
      )
      .pipe(
        map((response) => {
          return <RatingResponse[]>response['hydra:member'].map((object) => {
            object.createdAt = moment(object.createdAt).toDate();

            return object;
          });
        })
      )
      .toPromise();
  }
}
