import { Component, input, output } from '@angular/core';
import { Customer } from 'src/app/interfaces/customer';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';
import { NgIf } from '@angular/common';
import { DivClickDirective } from 'src/app/directives/div-click.directive';

@Component({
  selector: 'app-card-organisation',
  templateUrl: './card-organisation.component.html',
  standalone: true,
  imports: [
    NgIf,
    TranslateTextDirective,
    DivClickDirective,
    TranslateModule,
    TruncateModule,
  ],
})
export class CardOrganisationComponent {
  public readonly customer = input<Customer>();
  public readonly language = input<string>();
  public readonly canOpen = input<boolean>(true);
  public readonly openLink = output<void>();

  open() {
    this.openLink.emit();
  }
}
