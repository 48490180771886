export enum AnalyticsType {
  APP_STARTUP = 'app_startup',
  DEVICE_LANGUAGE = 'device_language',
  UPDATE_SHARED = 'update_shared',
  UPDATE_OPEN = 'update_open',
  UPDATE_REACT = 'update_react',
  UPDATE_VIEW = 'update_view',
  POLL_REACT = 'poll_react',
  SEARCH = 'search',
  SWITCH_VIEW = 'switch_view',
  SWITCH_TAB = 'switch_tab',
  SMART_REPORTING = 'smart_reporting',
  CONTACT_MESSAGE = 'contact_message',
  PROJECT_DETAIL_TAB_CHANGED = 'project_detail_tab_changed',
  PROJECT_SHARED = 'project_shared',
  LIVESTREAM_CLICKED = 'livestream_clicked',
  FEEDBACK_RATE = 'feedback_rate',
  SURVEY_FILLED = 'survey_filled',
  VIEW_LINK = 'view_link',
  NOTIFICATION_CLICKED = 'notification_clicked',
  PROJECT_FOLLOW = 'project_follow',
  PROJECT_UNFOLLOW = 'project_unfollow',
  IMAGE_SHARED = 'image_shared',
  OPEN_ATTACHMENT = 'open_attachment',
  OPENED_WITH_URL = 'opened_with_url',
}
