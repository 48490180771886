import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ActionSheetController,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DistanceType } from 'src/app/enums/distance-type';
import { SettingsService } from 'src/app/services/settings.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import {
  IonContent,
  IonBackButton,
  IonToggle,
} from '@ionic/angular/standalone';
import { ContrastOption } from 'src/app/enums/contrast-option';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.page.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InlineSVGModule,
    TranslateModule,
    IonContent,
    IonBackButton,
    IonToggle,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
  ],
})
export class PreferencesPage implements OnInit {
  preferencesForm: FormGroup;
  DistanceType = DistanceType;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private actionSheetCtrl: ActionSheetController,
    private translateService: TranslateService
  ) {
    this.createPreferencesForm();
  }

  async ngOnInit() {
    let suggestionsEnabled = await this.settingsService.getSuggestionsEnabled();
    if (suggestionsEnabled === null) suggestionsEnabled = true;
    let distanceType = await this.settingsService.getDistanceType();
    let contrast = await this.settingsService.getContrast();
    if (contrast === null) contrast = ContrastOption.SYSTEM;
    this.preferencesForm.patchValue(
      {
        suggestionsEnabled: suggestionsEnabled,
        distanceType: distanceType,
        contrast: contrast,
      },
      { emitEvent: false }
    );
  }

  async createPreferencesForm() {
    this.preferencesForm = this.fb.group({
      suggestionsEnabled: [],
      distanceType: [],
      contrast: [],
    });

    this.preferencesForm
      .get('suggestionsEnabled')
      .valueChanges.subscribe((value) => {
        this.settingsService.setSuggestionsEnabled(value).then(() => {
          this.settingsService.settingsChanged$.next(true);
        });
      });

    this.preferencesForm.get('distanceType').valueChanges.subscribe((value) => {
      this.settingsService.saveDistanceType(value).then(() => {
        this.settingsService.settingsChanged$.next(true);
      });
    });

    this.preferencesForm.get('contrast').valueChanges.subscribe((value) => {
      this.settingsService.saveContrast(value).then(() => {
        this.settingsService.checkContrast();
      });
    });
  }

  async showDistanceTypes() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant(
        'settings.preferences.distance-unit.title'
      ),
      cssClass: 'custom-actionsheet',
      buttons: [
        {
          text: this.translateService.instant(
            'settings.preferences.distance-unit.km.full'
          ),
          cssClass:
            this.preferencesForm.get('distanceType').value == DistanceType.KM
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm.get('distanceType').setValue(DistanceType.KM);
          },
        },
        {
          text: this.translateService.instant(
            'settings.preferences.distance-unit.mi.full'
          ),
          cssClass:
            this.preferencesForm.get('distanceType').value == DistanceType.MILES
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm
              .get('distanceType')
              .setValue(DistanceType.MILES);
          },
        },
        {
          text: this.translateService.instant('settings.cancel'),
          cssClass: 'cancelBtn',
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  async showContrastOptions() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant(
        'settings.preferences.contrast.title'
      ),
      cssClass: 'custom-actionsheet',
      buttons: [
        {
          text: this.translateService.instant(
            'settings.preferences.contrast.system'
          ),
          cssClass:
            this.preferencesForm.get('contrast').value == ContrastOption.SYSTEM
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm
              .get('contrast')
              .setValue(ContrastOption.SYSTEM);
          },
        },
        {
          text: this.translateService.instant(
            'settings.preferences.contrast.normal'
          ),
          cssClass:
            this.preferencesForm.get('contrast').value == ContrastOption.NORMAL
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm
              .get('contrast')
              .setValue(ContrastOption.NORMAL);
          },
        },
        {
          text: this.translateService.instant(
            'settings.preferences.contrast.high'
          ),
          cssClass:
            this.preferencesForm.get('contrast').value == ContrastOption.HIGH
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm.get('contrast').setValue(ContrastOption.HIGH);
          },
        },
        {
          text: this.translateService.instant('settings.cancel'),
          cssClass: 'cancelBtn',
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }
}
