import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PwaService } from './pwa.service';
import { DistanceType } from '../enums/distance-type';
import { BehaviorSubject, Subject } from 'rxjs';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { LocalNotifications } from '@capacitor/local-notifications';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ContrastOption } from '../enums/contrast-option';
import { WhiteLabelApplicationService } from './white-label-application.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public settingsChanged$ = new Subject<boolean>();
  private static SETTING_PREFIX = 'settings';
  private static SETTING_DISTANCE_TYPE = `${SettingsService.SETTING_PREFIX}DistanceType`;
  private static SETTING_CONTRAST = `${SettingsService.SETTING_PREFIX}Contrast`;
  private static SETTING_SUGGESTIONS_ENABLED = `${SettingsService.SETTING_PREFIX}'SuggestionsEnabled`;

  constructor(
    private storage: StorageService,
    private pwaService: PwaService,
    private currentApp: CurrentWhiteLabelApplication,
    private whitelabelService: WhiteLabelApplicationService
  ) {}

  public async saveDistanceType(distanceType: DistanceType) {
    await this.storage.set(SettingsService.SETTING_DISTANCE_TYPE, distanceType);
  }

  public async getDistanceType() {
    const type = await this.storage.get(SettingsService.SETTING_DISTANCE_TYPE);

    if (!type) {
      // determine initial value
      if (this.currentApp.getDistanceType() == DistanceType.MILES) {
        return DistanceType.MILES;
      } else {
        return DistanceType.KM;
      }
    }

    return type;
  }

  public async saveContrast(contrast: ContrastOption) {
    await this.storage.set(SettingsService.SETTING_CONTRAST, contrast);
    this.checkContrast();
  }

  public async getContrast() {
    return await this.storage.get(SettingsService.SETTING_CONTRAST);
  }

  public async checkContrast() {
    let contrast = await this.storage.get(SettingsService.SETTING_CONTRAST);
    if (contrast === null) contrast = ContrastOption.SYSTEM;
    const systemHighContrast = window.matchMedia(
      '(prefers-contrast: more)'
    ).matches;
    if (
      contrast === ContrastOption.HIGH ||
      (contrast === ContrastOption.SYSTEM && systemHighContrast)
    )
      this.setHighContrast();
    else this.setNormalContrast();
    return contrast;
  }

  private setHighContrast() {
    document.documentElement.style.setProperty('--appTint', '#212121');
    document.documentElement.style.setProperty('--appSupport', '#575757');
    document.documentElement.style.setProperty(
      '--ion-color-primary',
      '#212121'
    );
    document.documentElement.style.setProperty(
      '--ion-color-secondary',
      '#575757'
    );
  }

  private setNormalContrast() {
    document.documentElement.style.setProperty(
      '--appTint',
      this.whitelabelService.currentWhiteLabelApp.primaryColor
    );
    document.documentElement.style.setProperty(
      '--appSupport',
      this.whitelabelService.currentWhiteLabelApp.secondaryColor
    );

    document.documentElement.style.setProperty(
      '--ion-color-primary',
      this.whitelabelService.currentWhiteLabelApp.primaryColor
    );
    document.documentElement.style.setProperty(
      '--ion-color-secondary',
      this.whitelabelService.currentWhiteLabelApp.secondaryColor
    );
  }

  async osPushEnabled(): Promise<boolean> {
    if (this.pwaService.isPwa()) {
      return false;
    }

    let enabled = false;

    try {
      let permStatus = await LocalNotifications.checkPermissions();
      if (permStatus.display === 'granted') enabled = true;
    } catch (error) {
      // Capacitor not available
    }

    return enabled;
  }

  async setSuggestionsEnabled(enabled: boolean) {
    await this.storage.set(
      SettingsService.SETTING_SUGGESTIONS_ENABLED,
      enabled
    );
  }

  async getSuggestionsEnabled() {
    return await this.storage.get(SettingsService.SETTING_SUGGESTIONS_ENABLED);
  }

  async getAppVersion(): Promise<string> {
    let version: string;
    if (Capacitor.isNativePlatform()) {
      version = (await App.getInfo()).version;
    }
    return version;
  }
}
