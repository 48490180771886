<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </ion-buttons>
    <ion-title mode="md"> {{ 'page.settings.profile' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="app__content profile">
    <app-loader [loading]="true" [hidden]="!loading"></app-loader>
    <div
      class="profile__content"
      [hidden]="loading"
      [class.logged-in]="loggedIn"
    >
      <ng-container *ngIf="!loggedIn">
        <p>{{ 'page.settings.profile.desc' | translate }}</p>
        <div class="profile__content__buttons">
          <button routerLink="/login">
            <span inlineSVG="assets/icons/mail.svg"></span>
            {{ 'page.settings.profile.login.email' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="loggedIn">
        <p>
          <span inlineSVG="assets/icons/mail.svg"></span>
          <ng-container *ngIf="!loading">{{ user.email }}</ng-container>
        </p>
        <div class="profile__content__buttons">
          <button (click)="logout()" [disabled]="loading">
            {{ 'page.settings.profile.logout' | translate }}
          </button>
          <button (click)="remove()">
            {{ 'page.settings.profile.remove' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
