<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button mode="md" defaultHref="settings"></ion-back-button>
    </ion-buttons>
    <ion-title role="heading" aria-level="1" mode="md">
      {{ 'page.settings.permissions' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="app__description">
    <p>{{ 'page.settings.permissions.desc' | translate }}</p>
  </div>
  <div class="app__content permissions" [formGroup]="permissionsForm">
    <div class="permissions__box">
      <div class="permissions__box__content">
        <h2>{{ 'settings.permissions.notifications.title' | translate }}</h2>
        <p>{{ 'settings.permissions.notifications.desc' | translate }}</p>
      </div>
      <div class="permissions__box__toggle">
        <ion-toggle
          [attr.aria-label]="'settings.permissions.notifications.title' | translate"
          formControlName="notificationsEnabled"
        ></ion-toggle>
      </div>
    </div>

    <div class="permissions__box">
      <div class="permissions__box__content">
        <h2>{{ 'settings.permissions.location.title' | translate }}</h2>
        <p>{{ 'settings.permissions.location.desc' | translate }}</p>
      </div>
      <div class="permissions__box__toggle">
        <ion-toggle
          [attr.aria-label]="'settings.permissions.location.title' | translate"
          formControlName="locationEnabled"
        ></ion-toggle>
      </div>
    </div>
  </div>
</ion-content>
